.projects-page {
    height: 80%;
    width: 80%;
    margin: auto;
}
.projects-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
    width: 60%;
    height: 60&;

}

.project {
    position: relative;
    border-radius: 10px;
    height: 200px;
    width: 400px;

    img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        
        z-index: 4;
        animation: backInUp 1s linear both;
    }

}

@for $i from 1 through 15 {
    #project#{$i} {
        animation-delay: #{$i / 10}s;
    }
}

//everything thats hidden inside project div

.hover-items {
    font-family: 'Coolvetica';
    background-color: #181818;
    text-align: center;
    color: #fff;
    font-size: 20px;
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 6;
    left: 0;
    top: 0;
    opacity: 0;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    a {
        color: grey;
    }
}

.hover-items1 {
    font-family: 'Coolvetica';
    background-color: #181818;
    text-align: center;
    color: #6edf8a;
    font-size: 20px;
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 6;
    left: 0;
    top: 0;
    opacity: 0;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    a {
        color: grey;
    }
}


.hover-items1:hover {
    opacity: 1;
}


//media queries for responsive web design

@media only screen and (max-width: 2060px) {
    .projects-page {
        overflow: auto;
        width: 100%;
        height: 100%;
    }
    .projects-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
  }

@media only screen and (max-width: 1540px) {
    .projects-container {
        grid-template-columns: 1fr 1fr;
    }
    .projects-page {
        display: flex;
        justify-content: center;
    }
  }

@media only screen and (max-width: 1060px) {
    .projects-container {
        grid-template-columns: 1fr;
        margin: 0 auto;
    }
  }

 @media only screen and (max-width: 600px) {
    .projects-container {
        width: 100%;
        grid-template-columns: 1fr;
        margin-left: 5px;
        overflow: auto;
    }

    .projects-page {
        height: 100%;
        width: 100%;
        margin: auto;
        margin-bottom: 10px;
        margin-top: 65px;
    }
    .project {
        position: relative;
        border-radius: 10px;
        height: 90%;
        width: 90%;
    }
    img {
        width: 100%;
    }
    .hover-items1 {
        font-family: 'Coolvetica';
        background-color: #181818;
        text-align: center;
        color: #fff;
        font-size: 15px;
        position: absolute;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 6;
        left: 0;
        top: 0;
        opacity: 0;
        border-radius: 10px;
        transition: all .4s ease-in-out;
        p {
            margin-bottom: -0px;
        }
        a {
            color: grey;
        }
    }
  }



